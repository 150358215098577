import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/nedix/projects/internal/nedix.io/git_modules/gatsby-theme-carbon/packages/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ImageCard = makeShortcode("ImageCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`C# is commonly used for developing desktop applications, mobile applications, web applications, and back-end services.`}</p>
    <p>{`Key features of C# are its type safety and memory management, which make it a reliable choice for building complex applications.`}</p>
    <p>{`Additionally, C# offers a wide range of programming features, including LINQ for querying data, asynchronous programming using async/await, and support for functional programming concepts like lambda expressions.`}</p>
    <p>{`C#‘s large and active community has contributed to a large range of third-party libraries and frameworks, including:`}</p>
    <ul>
      <li parentName="ul">{`ASP.NET for web development`}</li>
      <li parentName="ul">{`Unity for game development`}</li>
      <li parentName="ul">{`Xamarin and MAUI for mobile development`}</li>
    </ul>
    <h2>{`Frameworks`}</h2>
    <Row mdxType="Row">
      <Column colMd={3} colLg={3} mdxType="Column">
  <ImageCard title="ASP.NET" titleColor="dark" actionIcon="arrowRight" iconColor="dark" href="/technologies/frameworks/asp.net/overview/" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsSAAALEgHS3X78AAADSklEQVQ4y7VUS28bZRQ900aAhAQBhIqQICFpSuwkM/GMPX6mbtM83CiJQ+KMk/qRxJ4ZP8GJKKqoUKv8A1Al2PAfWLFiAwsWCDWN4xnbmbYeO/ErdlwJ6IpFBpWqkVoRsCp6pCvdT/fe892zuAeapuGk+Dc8qh8dHT3V/+h9nDyJdgmf7T2eOemndjY8kfB43f8D7UpsG7Xin9gMlBFzFrHB/QiBbSDCNrE+XkTc1oBgLUFgVURH8ohZ8ohdyiI5tgV+JAfBkYNwPgPekYVolyDYZOCrTxoQTSXwjArRWII4vgfR0MS1b35F3HqIsLEF7ScNC2SW8JAZgusvYFGnENPdCuF6tQx3V4GY6skRl15vYbTzEPD3q+CZIni6gIhxD7GJIkS6+cpn0JCwtjrC7CG0BxoWh7PwkDvw9pfADSiY7roLFg8x33sfM305TL1Tw+V3q0DEWiREiwrRrOqj5kKn6Kh+ELc39OujpTNxW93Km2uDH+lyCLI5wUdLE2sWhVozS04/s7u5SiszQWPuuo/Jfb1C7+qD9C4QoDL41qlBMKscz5aYsKNOhewHZ1MX1TMxS40SzGXHVPcdBE3yLZ9B+i7AyBt+Oh1doqQfVhmFXh5OL3ipnS9n9T/DrfvlFMJGFWGm2Bs9X5wUbeWJiKnxGm8+MEQde66kvT7K09U3U5PltwNG+YbfkI366IzkN2wFl8md70Xbbo+XvOPkyPQNjz6NBV26AzxTBm8sv3fV20R85I+3UhcPdFG2MRAfqbyfsjde9rKllwT7/kyIVVyrjPpGgMnF10wZyjcs3wzQ+TE/KXVfoeQx74AETi+dAs9UEBquYH26SkQtD5E01hHuqiBmr+Ljnt+AzlsI2/axTObhHSzAS8pYHkpjri8LT78C7pyM+Q9lzJ2VCXevDHDd+whRFawM3EPE9gAJuo5QbxUxe41ImFqYGlIQsu4TS2SeWBq6C47Mnl4aTBNzfdnTnnMKsfiY8G+y2R7p8aVcYbdxc6UIK37HhruJxHgL8x0arn96G0lHE4kLFXwxex+bsyVcu3wPn7tlXHWlsT6ZwYZrC6nxNJIXtpFwbr+A03shhE/sqh3b+i9f/EdzfV6FT/nh80p/dvYveXUhVKBy9iIAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "ASP.NET",
              "title": "ASP.NET",
              "src": "/static/8a4f6ccbae0fc500ca78003b2838c96d/3cbba/asp-net.png",
              "srcSet": ["/static/8a4f6ccbae0fc500ca78003b2838c96d/7fc1e/asp-net.png 288w", "/static/8a4f6ccbae0fc500ca78003b2838c96d/a5df1/asp-net.png 576w", "/static/8a4f6ccbae0fc500ca78003b2838c96d/3cbba/asp-net.png 1152w", "/static/8a4f6ccbae0fc500ca78003b2838c96d/4c487/asp-net.png 1280w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      